<template>
  <div v-if="loaded" class="domain-service-info-slide-container">
    <primary-button
      button-text="Go to Websites"
      aria-label="go to websites"
      @onclick="goToWebsites()"
    />
  </div>

  <div v-else>
    <div class="d-flex justify-content-center mt-2 mb-2">
      <b-spinner variant="primary" large />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'DomainServiceInfo',
  components: {
    PrimaryButton:  () => import('@/components/StagelineV2/shared/PrimaryButton'),
  },
  props: {
    slide: null,
  },
  data() {
    return {
      loaded: true,
    }
  },
  computed: {
    ...mapGetters('domains', [
      'domain',
    ]),
    ...mapGetters('stageline', [
      'currentStage',
    ]),
  },
  methods: {
    ...mapActions('stageline', [
      'completeStageForRedirect',
    ]),
    async goToWebsites() {
      await this.completeStageForRedirect(this.currentStage.id)
      await this.$router.push({ name: 'websites' }).catch(() => {})
    },
  },
}
</script>
<style scoped lang="scss">
.domain-service-info-slide-container {
  text-align: center;
  margin-top: 1em;
}
</style>
