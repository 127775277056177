var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.loaded
    ? _c(
        "div",
        { staticClass: "domain-service-info-slide-container" },
        [
          _c("primary-button", {
            attrs: {
              "button-text": "Go to Websites",
              "aria-label": "go to websites",
            },
            on: {
              onclick: function ($event) {
                return _vm.goToWebsites()
              },
            },
          }),
        ],
        1
      )
    : _c("div", [
        _c(
          "div",
          { staticClass: "d-flex justify-content-center mt-2 mb-2" },
          [_c("b-spinner", { attrs: { variant: "primary", large: "" } })],
          1
        ),
      ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }